<template>
  <b-row>
    <modal-user-wallpaper-history ref="refModalUserWallpaperHistory" />

    <b-col sm="12">
      <table-server-side-v-1-widget
        url="api/admin/userTransactionData?filter=purchaseImage"
        :columns="columns"
        :actions="actions"
        @clickViewDetail="row => $refs.refModalUserWallpaperHistory.show(row)"
        @clickViewUser="onClickViewUser"
      />
    </b-col>
  </b-row>
</template>

<script>
import ModalUserWallpaperHistory from '../../../userManagement/components/v2/ModalUserWallpaperHistory.vue'

export default {
  name: 'IndexPurchaseImageTable',
  components: {
    ModalUserWallpaperHistory,
  },

  data() {
    return {
      columns: [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'user_as_email',
        },
        {
          label: 'ชื่อ ที่สร้าง',
          field: 'image_data_transaction_data_as_text',
        },
        {
          label: 'รูปหลัก',
          field: 'topic_datas_as_name',
        },
        {
          label: 'เครดิตเดิม',
          field: 'transaction_data_as_credit_balance',
          tdClass: 'text-right',
          formatFn: value => {
            if (!value) return '-'
            return this.gFormatNumberToCurrency(value)
          },
        },
        {
          label: 'เครดิตลด',
          field: 'transaction_data_as_credit_amount',
          type: 'currency',
          tdClass: 'text-right',
        },
        {
          label: 'เครดิตคงเหลือ',
          field: 'transaction_data_as_credit_total',
          tdClass: 'text-right',
          formatFn: value => {
            if (!value) return '-'
            return this.gFormatNumberToCurrency(value)
          },
        },
        {
          label: 'วันที่ (ปี-เดือน-วัน)',
          field: 'created_at',
          formatFn: value => this.$date(value).format('YYYY-MM-DD HH:mm:ss'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      actions: [
        { keyEmit: 'clickViewDetail', label: 'ดูรายละเอียดการซื้อรูป', iconName: 'EyeIcon' },
        { keyEmit: 'clickViewUser', label: 'ไปหน้าข้อมูลลูกค้า', iconName: 'UserIcon' },
      ],
    }
  },
  methods: {
    onClickViewUser(row) {
      // console.log('onClickViewUser', row)
      this.$router.push({ name: 'userManagement-user-info', params: { userId: row.image_transaction_data_as_user_id } })
    },
  },
}
</script>

<style lang="scss"></style>
